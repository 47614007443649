<template>
  <div class="user">
    <div class="tit">{{ $t('reg_limit_1') }}</div>
    <div class="user-box">
      <div class="limit-tit">{{ $t('reg_limit_2') }}：</div>
      <div class="limit-cont">{{ list.name }}</div>
      <div class="limit-tit">{{ $t('reg_limit_3') }} ：</div>
      <div class="limit-cont">$ {{ list.money }}</div>
      <!-- <div class="tip">{{ $t('reg_limit_4') }}</div> -->
    </div>
    <div class="textCenter">
      <el-button :loading="nextLoading" @click="next" type="primary">
        {{ $t('reg_limit_5') }}
      </el-button>
      <el-button @click="back" type="text">{{ $t('reg_limit_6') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
        email: localStorage.getItem('sm_email'),
      },
      list: {},
      type: localStorage.getItem('sm_type'),
      nextLoading: false,
    }
  },
  created() {
    this.getQuota()
    if (localStorage.getItem('sm_userinfo')) {
      this.ruleForm = {
        ...JSON.parse(localStorage.getItem('sm_userinfo')),
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
      }
    }
  },
  methods: {
    async getQuota() {
      let res = await this.$axios.post('/api/Config/quota')
      if (res.code === 1) {
        this.list = res.data
      }
    },
    back() {
      this.$router.push({ name: 'BankInfo' })
    },
    async next() {
      this.nextLoading = true
      let res = await this.$axios.post('/api/User/register', this.ruleForm)
      this.nextLoading = false
      if (res.code === 1) {
        this.$router.push({ name: 'SetPassword' })
        localStorage.setItem('sm_userinfo', JSON.stringify(res.data.userinfo))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 400px;
  margin: 80px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
}
.limit-tit {
  font-size: 22px;
  color: #333333;
  margin-bottom: 10px;
}
.limit-cont {
  font-size: 24px;
  color: #000000;
  margin-bottom: 20px;
  margin-left: 2em;
}
.tip {
  font-size: 18px;
  color: #999999;
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
}
</style>
